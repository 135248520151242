
const btnMenu = document.querySelector('.btn-menu')
const navList = document.querySelector('.nav-list')
const copy = document.querySelector('.copy p')
const partners = document.querySelector('.partners')
const ourClientsSayh2 = document.querySelector('.our-clients-say h2')
const clients = document.querySelector('.clients')
const sizeChanges = window.matchMedia('(max-width: 1200px)')
const inputRequired = document.querySelectorAll('.inputRequired')


// Menu
btnMenu.addEventListener('click', () => {
    btnMenu.classList.toggle('opened');
    navList.classList.toggle('opened');
});

window.addEventListener('scroll', () => {
    let header = document.querySelector('.header')
    
    if(!navList.classList.contains('opened')){
        header.classList.toggle('sticky', window.scrollY > 0)
    }
    
})

// Responsive Changes
if(sizeChanges.matches) {
    copy.style.cssText = `
    border-top: 1px solid #676767;
    padding-top: 20px;
    font-size: 14px;
    `
    if(ourClientsSayh2){
        ourClientsSayh2.after(clients)
    }
    partners.after(copy)
}

// Inputs Required 
inputRequired.forEach((item) => {
    item.addEventListener('focus', () => {
        item.nextElementSibling.style.display = 'none'
    })
    item.addEventListener('focusout', () => {
        if(item.value === ''){
            item.nextElementSibling.style.display = 'block'
        }
    })
})








